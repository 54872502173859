<template>
  <h2 class="title">
    <span v-if="roof" class="roof">{{ roof }}</span>

    <component :is="link ? 'a' : 'span'" :href="link" class="main">
      {{ main }}
      <BaseIcon
        v-if="link"
        class="icon"
        name="material-symbols:arrow-forward-ios"
      />
    </component>
  </h2>
</template>

<script lang="ts" setup>
import type { Title } from '../models';

const props = defineProps<{
  content: Title;
}>();

const roof = computed(() => props.content.roof);
const main = computed(() => props.content.main);
const link = computed(() => props.content.link);
</script>

<style src="./Title.scss" scoped lang="scss"></style>
