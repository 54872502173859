<template>
  <article class="teaserBox">
    <TeaserBoxFig :content="content.fig || fallbackImage" />

    <TeaserBoxText :content="content" />

    <TeaserBoxLike
      v-if="widgetConfig?.quickAccessList && isLocalStorageAvailable()"
      :model-value="isLiked"
      @click="toggleLike"
    />
  </article>
</template>

<script lang="ts" setup>
import type { BaseImage } from '../../../models/BaseImage';
import type { TeaserBox } from './models';
const widgetConfig = await useWidgetConfig();
const storage = useLocalStorage<string[]>('bookmarks', []);
const moduleType = useWhlModuleType();

const props = defineProps<{
  content: TeaserBox;
}>();

const id = toRef(() => props.content.id);

const fallbackImage: BaseImage = {
  deeplink: 'dummy-image.jpg',
  pooledMedium: {
    altText: 'Fallback Image',
    title: 'Dummy Caption',
    copyright: '',
  },
};

const isLiked = computed(() => {
  if (!moduleType.value || !id.value) return false;
  const itemKey = `${moduleType.value}_${id.value}`;

  return storage.value.includes(itemKey);
});

const toggleLike = () => {
  if (!moduleType.value || !id.value) return;

  const itemKey = `${moduleType.value}_${id.value}`;

  if (isLiked.value) {
    useGlobalStore().removeBookmark(itemKey);
  } else {
    useGlobalStore().addBookmark(itemKey);
  }
};
</script>

<style src="./Box.scss" scoped lang="scss"></style>
