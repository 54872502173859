<template>
  <section class="sliderTeaser">
    <div class="global-grid">
      <div class="sliderTeaserWrap">
        <SliderTeaserTitle v-if="!isEmpty(title)" :content="title" />

        <SliderTeaserItems
          v-if="!isEmpty(items)"
          ref="slider"
          :content="items"
          :type="type"
          @swiper-update="(e) => (sliderInfo = e)"
        />

        <SliderPagination
          v-if="!isEmpty(items)"
          :swiper-instance="slider?.swiperInstance"
          :slider-info="sliderInfo"
        />
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import type { SliderTeaser, Items } from './models';

const slider = ref<Items | null>(null);

const props = defineProps<{
  content: SliderTeaser;
}>();

const title = toRef(() => props.content?.title);
const type = toRef(() => props.content?.type);
const items = toRef(() => props.content?.items);

const sliderInfo = ref<{
  label: string;
  hasNext: boolean;
  hasPrev: boolean;
} | null>(null);
</script>

<style src="./Teaser.scss" scoped lang="scss"></style>
