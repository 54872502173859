<template>
  <div class="datelistWrap">
    <span class="info">{{ $t('components.datelist.info') }}</span>

    <ul class="datelist">
      <li v-for="(item, index) in displayedItems" :key="index" class="item">
        <a v-if="item.link" class="link" :href="item.link">{{ item.text }}</a>
        <span v-else class="span">{{ item.text }}</span>
      </li>

      <li v-if="additionalCount > 0" class="item">
        <button
          v-show="!showAll"
          class="additional link"
          @click="toggleShowAll"
        >
          + {{ additionalCount }}
        </button>
        <button v-show="showAll" class="additional link" @click="toggleShowAll">
          {{ $t('components.datelist.button.collapse') }}
        </button>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import type { Datelist } from '../../models';

const props = defineProps<{
  content: Datelist;
}>();

const showAll = ref(false);

const displayedItems = computed(() => {
  return showAll.value ? props.content.items : props.content.items.slice(0, 2);
});

const additionalCount = computed(() => {
  return props.content.items.length > 2 ? props.content.items.length - 2 : 0;
});

function toggleShowAll() {
  showAll.value = !showAll.value;
}
</script>

<style src="./Datelist.scss" scoped lang="scss"></style>
